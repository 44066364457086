export const language = {
    abloutwe: "<span style='font-size:12px'>About Us</span>",
    DeliciousYantai: "Delicious Yantai",
    DynamicDevelopmentZone: "Dynamic development zone",
    CompanyIntroduction: "Company introduction",
    vrView: "vr",
    ContactUs: "Contact us",
    YantaiInternationalFurniture: "Annual blockbuster · Yantai International Furniture Fair is about to open!",
    hostentry: "The host entry",
    giftExhibitionwill: "240,000 square meters of Yantai gift Exhibition will be in Yantai International Convention and Exhibition Center...",
    InternationalConventionand: "ISLE 2021 was held in Yantai International Convention and Exhibition Center",
    inbookingthevenue: "As the host, I am interested in booking the venue",
    PavilionGuide: "<span style='font-size:12px'>Pavilion Guide</span>",
    ExhibitionHallSurvey: "Overview of the exhibition hall",
    exhibitionGround: "Exhibition ground",
    andExhibitionCenter: "Annual blockbuster · Yantai International Furniture Fair is about to open!  ISLE 2021 was held in Yantai International Convention and Exhibition Center  ",
    ConferenceCenter: "Conference Center",
    ExquisiteGroupPictures: "Exquisite group pictures",
    ExhibitionTrends: "<span style='font-size:12px'>Exhibition Trends</span>",
    NewInformation: "News information",
    WonderfulMoment: "Wonderful moment",
    ExhibitionScheduling: "Exhibition scheduling",
    ExhibitionServices: "<span style='font-size:12px'>Exhibition Services</span>",
    DataDownload: "<span style='font-size:12px'>Data Download</span>",
    HallPlan: "Hall plan",
    Cityshoreseaofcloud: "City shore sea of cloud",
    Theexhibitionarea: "The exhibition area",
    Assemblyarea: "Assembly area",
    SiteDynamicLineMap: "Site dynamic line map",
    Brochure: "Brochure",
    shandongyantai:"Address <span style='margin:0 5px 0 3px;'>   :    </span> 31 Beijing Middle Road, Yantai Economic and Technological Development Zone, Shandong Province",
    preview: "preview",
    ManagementRegulations: "Management regulations",
    ApprovalDocument: "Approval document",
    PeripheralServices: "<span style='font-size:12px'>Peripheral Services</span>",
    TrafficGuide: "Traffic guide",
    ParkingInformation: "Parking information",
    SurroundingHotels: "Surrounding hotels",
    ScenicSpot: "Scenic spot",
    Chinese: "<span style='font-size:12px'>Ch</span>",
    En: "<span style='font-size:12px'>En</span>",
    ExhibitorEntrance: "Exhibitor entrance",
    ExhibitorEntranceText: "As an exhibitor, I am interested in participating in the exhibition arrangement",
    AudienceEntrance: "Audience entrance",
    AudienceEntranceText: "As an audience, I am interested in visiting the exhibition",
    PavilionNews: "Pavilion news",
    ExhibitionHallScheduling: "Exhibition hall scheduling",
    ExhibitionEquipmentRental: "Exhibition equipment rental",
    FoodAndBeverages: "Food and Beverages",
    BuildService: "Build service",
    AdvertisingServices: "Advertising services",
    EntranceMovingLine: "Entrance moving line",
    VenueTechnicalParameters: "Venue technical parameters",
    leftTitle: "2021 China International Hotel investment franchise and franchise",
    Organizer: "Sponsor: Yantai Agricultural Culture Communication Co., Ltd.",
    LeftNews: "China Agricultural Products Expo 2021",
    CenterTitle: "2021 China International Hotel investment franchise and franchise",
    CenterText: "The 2021 China International Hotel investment, franchise and Franchise Exhibition hosted by Shandong Yantai International Exhibition Co., Ltd. was held at 9:00 a.m. on the 26th",
    RightTitle: "2021 China International Hotel investment franchise and franchise",
    RightText: "The 2021 China International Hotel investment, franchise and Franchise Exhibition hosted by Shandong Yantai International Exhibition Co., Ltd. was held at 9:00 a.m. on the 26th",
    BrowseMore: "Browse more",
    Link: "Link: China Convention and Exhibition Economic Research Association, China Exhibition Hall Association, Yantai industry reaching Development Group Co., Ltd",
    Linkx: "Yantai Yeda International Exhibition Co., Ltd. Yantai Yeda Cultural Tourism Co., Ltd",
    ExhibitionCore: "Yantai Bajiao International Convention and Exhibition Center",
    Telephone: "Telephone",
    Address: "Address: Bajiao Bay, Yantai Economic and Technological Development Zone",
    WeChatOfficialAccount: "WeChat official account",
    Record: "COPYRIGHT © 2021 Yantai bajiaowan International Convention and Exhibition Center allright8 re8erved | Lu ICP Bei 2021032259",
    Follow: "follow",
    // 鲜美烟台
    current: "The current position",
    HOMEpage: "Home page",

    introduced: "The name of Yantai originates from Yantai Mountain. It is the central city of Shandong Peninsula in China approved by The State Council. It is an important port city in bohai Rim region and a famous national historical and cultural city. In 2020, Yantai will continue to retain the honorary title of national Civilized City and become the only prefecture-level city with 'six consecutive crowns' in China. Yantai is located in the middle of Shandong Peninsula, with Weihai in the east, Weifang in the west, Qingdao in the southwest, Bohai sea and Yellow Sea in the north, facing liaoning Peninsula and Dalian across the sea, forming a maritime gateway to the capital Of Beijing. Yantai is an important node city in bohai Rim economic Circle and Jiaodong Economic circle, one of the first 14 coastal open cities in China, a coastal city in China, and a national strategic key port city of 'One Belt and One Road'. Yantai mountain green water show, pleasant climate, rich in natural landscape and cultural landscape. Especially in summer, the sea breeze caresses the face, refreshing but person; Flowers in full bloom, gladdening the heart; The sea and the mountains add radiance to each other. It is not only a cool summer resort, but also an ideal place for tourists from all over the world. There are many tourist attractions in the city, including 3 national 5A level tourist attractions, 19 4A level tourist attractions and 50 3A level tourist attractions. The city has won the title of 'China Excellent Tourist City'.",
    honor: "City of honor",
    InternationalWineCity: "VIO International Wine City",
    HabitatAward: "United Nations Habitat Award",
    investmentenvironment: "China's gold city for investment environment",
    citiesinChina: "Top ten civilized cities in China",
    CityinChina: "Best Charming City in China",
    beautifulCITY: "Most beautiful city in China",
    HealthCity: "National Health City",
    GardenCity: "National Garden City",
    ForestCity: "National Forest City",
    resortcity: "Summer resort city",
    competitivecity: "China's most ecologically competitive city",
    // bottom公共组件
    bq: "<span style='font-size:12px;'>Copyright yantai Bajiao Bay International Convention and Exhibition Center. All rights reserved</span>",
    bqcontent: "<span style='font-size:12px;'>COPYRIGHT © 2021 Yantai Bajiaowan International</span>",
    friendship: "<span style='font-size:12px;'>China Convention and Exhibition Association Yantai Yeda City Development Group Co., LTD</span>",
    Developmentofthecompany: "<span style='font-size:12px;'>Yantai Yeda International Exhibition Co., LTD</span>",
    Thepublic: "Wechat official account",
    // 展馆概况
    ExhibitionCenter: "Yantai Bajiao Bay International Convention and Exhibition Center",
    expl: "Yantai Bajiao Bay International Convention and Exhibition Center is located in Bajiao Street, Yantai Development Zone, covering an area of 209.27 mu with a construction area of 200,000 square meters.Total investment of about 3.2 billion yuan, by the famous American design companyAccording to the positioning design of the fifth generation exhibition complex, AECOM is designed to 'city shore'Cloud wave sea silver shell' is the design concept, yantai blue sea and blue sky, waves and white clouds blend, and then into the light silverIn the end, the architectural structure and shape of the bay evolved into a unified and clear structure, with exhibition area, conference area and supporting business district as oneThe convention and exhibition business body",
    YantaiBajiao: "Yantai Bajiao Bay International Convention and Exhibition Center is located in Bajiao Street, Yantai Development Zone, covering an area of 209.27 mu, construction area of 200,000 square meters, the total investment of about 3.2 billion yuan.",
    Theexhibition: "The exhibition center is divided into indoor exhibition area and outdoor exhibition area. There are 8 indoor exhibition halls with an indoor exhibition area of 66,000 square meters and a total of 3,300 booths. The outdoor exhibition area is about 33,000 square meters, which can provide exhibition space for various exhibition activities.",
    Theconference: "The conference area of the convention and Exhibition Center has five floors and an area of 30,000 square meters. According to the needs, there are a total of 30 conference rooms of different sizes, which can accommodate more than 3,600 people in total, and can undertake various kinds of conferences, banquets, forums and lectures, corporate annual meetings and other large indoor activities",
    Inaddition: "In addition, the overall design of the convention and Exhibition center adopts environmental protection advanced building technology and intelligent system, equipped with intelligent parking technology for more than 1800 indoor and outdoor parking Spaces; The 2500 square meter kitchen can provide a variety of forms of food enjoyment for exhibitors, conference and other personnel.",
    // 活力开发区
    Approvedbythe: "Approved by the State Council in 1984, construction began in March 1985, is one of the first batch of 14 state-level development zone, China (shandong) free trade area of yantai area bearing, is smoke < br > international investment industrial park, China and South Korea industrial park, the industrial park and heald protect area main land, shandong kinetic energy conversion between the old and the new core.  Has won the ISO14000 National Demonstration Area, China Industrial Park Environmental Management Demonstration Area, national Circular Economy pilot park, national New Industrialization demonstration Base, national Intellectual property pilot < BR > Park, National Model Industrial Park of Harmonious Labor Relations, the United Nations Green Industrial Park and other titles. In 2020, GDP will grow by 5.5%, investment in fixed < BR > assets will grow by 7%, total import and export will grow by 14.9%, actually used foreign capital will grow by 16.6%, and general public budget revenue will grow by 5.3%. The main economic indicators are all higher than the average level of provinces and cities.",
    WonderfuloctagonBay: "Wonderful octagon Bay",
    BajiaoBayis: "Bajiao Bay is a land of hope. 'innovation' is one of its 'labels', and 'culture, tourism and exhibition' is also a distinct feature of this area.",
    BajiaowanCentral: "Bajiaowan Central Innovation Zone, located in the center of Yantai Development Zone, is mainly designed as a central innovation zone. The layout of the central business District, science and technology innovation district, exhibition cultural tourism district 'three blocks'. Some areas of Bajiao Bay are also included in yantai area of Shandong Free Trade Pilot Zone and China and South Korea (Yantai) Industrial Park.",
    Asoneofthenine: "As one of the nine key areas planned and constructed in Yantai city, Bajiao Bay is located in the west of Yantai Development Zone and has become one of the golden Bay areas with the most potential for development. This can be seen from the layout of surrounding industries. Around bajiao Bay, there are a number of high-quality industrial leading projects such as Wanhua, Foxconn and GENERAL Motors. Close to 'three ports and one station', 10 kilometers away from Penglai International Airport, 5.8 kilometers away from Yantai West Railway Station of Bohai Rim high-speed Railway, Rongwu Expressway and 206 National Highway pass through the border, and adjacent to the West Port area of Yantai Port in the north, which integrates various advantages of location, natural resources and economic hinterland.",
    // 公司介绍
    YantaiYedaUrban: "Yantai Yeda Urban Development Group Co., Ltd. is a key state-owned enterprise in Yantai Economic and Technological Development Zone. It was established in September 2019 with registered capital of 3 billion yuan. By the end of 2020, the total assets are about 26.5 billion yuan, the net assets are 15.9 billion yuan, and there are more than 400 employees. It is a state-owned capital investment and operation platform with urban renewal and construction, urban comprehensive operation services, industrial cultivation and financial services, exhibition, cultural tourism and Marine economy as the core four major industries.",
    YantaiYeDaWen: "Yantai Ye Da Wen Travel Co., LTD",
    Foundedin2019with: "Founded in 2019 with a registered capital of 500 million yuan, Yantai Yeda Tourism Co., Ltd. is a wholly-owned subsidiary of Yantai Yeda Urban Development Group Co., LTD., a regional state-owned enterprise. Industry of the tour group, adhering to the 'better life' brand philosophy, to enhance the quality of modern coastal city, for the purpose of service forms, expand the Marine economy prosperity, dig, retain cultural tourism city background, committed to build the most attractive of yantai city plate, expand the exhibition text brigade new chapter, for the construction of modernization and civilization of beautiful seashore city power!",
    YantaiYedaInternational: "Yantai Yeda International Exhibition Co. LTD",
    YantaiYedaInternationalExhibitionCo: "Yantai Yeda International Exhibition Co., LTD., founded in August 2020 with a registered capital of 5 million yuan, is a third-level wholly-owned subsidiary of Yantai Yeda Urban Development Group Co., LTD., a regional state-owned enterprise. Yeda International Exhibition Co., Ltd. is one of the core enterprises of Yeda Chengfa Group to perform the city operation service function, and is the venue operator of Yantai Bajiao Bay International Exhibition Center. The company consists of Marketing Department, operation department, conference & Catering Department, Engineering & Property Department, HR & Administration Department and Finance department. The company is committed to providing attendees with the best service experience integrating exhibition, conference, catering, leisure, sightseeing and shopping with the international business philosophy, advanced management system and the best service quality.",
    // 联系我们
    Site: "site",
    YantaiEconomic: "Yantai Economic and Technological Development Zone, Shandong Province",
    MiddleRoad: "31 Beijing Middle Road",
    Phone: "phone",
    Mailbox: "mailbox",
    WechatQRcode: "Wechat QR code",
    // 展馆场地
    Venueintroduction: "Venue introduction",
    Logontothehall: "Log on to the hall",
    Themulti: "The multi-function hall",
    Exhibitiongalleriesforindoor: "Exhibition galleries for indoor and outdoor exhibition, interior altogether has eight exhibition hall, indoor exhibition area of 66000 square meters, the total booth 3299, the first double layer by five plus three exhibition halls of standard units, using the difference of the site cleverly made can realize the ground flat into eight exhibition hall, each exhibition hall which owns independent logistics and flow channel; The outdoor exhibition hall covers an area of about 33,000 square meters, providing exhibition space for various exhibition activities.",
    Thedesignstyleoftheentry: "The design style of the entry hall is harmonious and unified with the exterior architecture. The tall interior space and simple lines draw the outline, making people feel like being in the sea of clouds. 'Although less prosperous, elegant and strange', the large area of ultra-white glass curtain wall fully brings in the natural light, creating a sense of simple and elegant atmosphere.",
    Thelargepillarfunctional: "The large pillar-free multi-functional hall of 4000 square meters is simple and luxurious, and elegant. Suitable for large conferences, banquets, corporate annual meetings and various customized activities.",
    ofexhibitioncenter: "Technical data of exhibition center",
    facility: "facility",
    A1A2pavilion: "A1, A2 pavilion",
    A3A4: "A3, A4 pavilion",
    B1B2B3: "Pavilions B1, B2 and B3",
    functionhall: "The multi-function hall",
    loginhall: "Primary and secondary login hall",
    supply: "supply",
    kvapavilion: "3200 kva/pavilion",
    supplymode: "The power supply mode",
    lighting: "Three-phase five-wire lighting 22 V 50 Hz",
    wiresystem: "Three-phase five-wire system",
    compressedair: "The compressed air",
    Thereisno: "There is no",
    twoposition: "Ø 12, two position",
    Whoseheight: "Whose height",
    meters129: "12.9 meters",
    lowestpoint: "26.1 m (lowest point)",
    lowespoint: "33.1 m, 26.5 m, 19.4 m (lowest point)",
    lowepoint: "32.4 m, 22.6 m (lowest point)",
    Buildallowedheight: "Build allowed height",
    meters6: "6 meters",
    structuralliftingpoints: "Number of structural lifting points",
    liftingpointbearing: "Structural lifting point bearing",
    ofexhibitionhall: "Floor bearing capacity of exhibition hall",
    tonspersquaremeter2: "2 tons per square meter",
    tonspersquaremeter5: "5 tons per square meter",
    tonspersquaremeter35: "0.35 tons per square meter",
    Tothegate: "To the gate",
    DN15DN25: "DN15 DN25",
    DN50DN100: "DN50 DN100",
    airconditioner: "air conditioner",
    Thereare: "There are",
    Freshair: "Fresh air",
    Telephonecontact: "Telephone contact",
    TheInternetpoint: "The Internet point",
    ExhibitionHall: "exhibition hall",
    Area: "area",
    StoryHeight: "story height",
    LoadBearing: "load-bearing",
    LiftingPointNumber: "Lifting point number/load-bearing",
    //周边酒店
    WestinHotels: "Westin Hotels",
    FiveStar: "Five Star",
    Locatednextto: "Located next to Bajiaowan International Convention and Exhibition Center in development Zone, covering an area of 54,000 square meters, it is composed of three internationally renowned star hotels and a service apartment, with a total of 1200 rooms.  ",
    WestinHotelconstruction: "Westin Hotel, construction area of 45,000 square meters, configuration area of 55 square meters, the height of 4.2 meters of 269 rooms.  Aloft Hotel has a construction area of 30,000 square meters, 361 rooms with 36 square meters of configuration area and 3.6 meters of height. ",
    TheHyattPlacehas336: "The Hyatt Place has 336 rooms with an area of 36 square meters and a height of 3.6 meters.",
    Thefirstfloorof: "The first floor of the service apartment is connected to the Hyatt Place hotel, with 201 rooms covering 36 square meters.",
    Morehotels: "More hotels",
    hotelname: "hotel name",
    starlevel: "star level",
    hoteladdress: "hotel address",
    Mileage: "Mileage (km)",
    Drive: "Drive (min)",
    numberofrooms: "number of rooms",
    CoastHotel: "Hilton Yantai Gold Coast Hotel",
    DevelopmentZone: "1 Ningbo Road, Development Zone",
    MarriottHotel: "Yantai Xinguang Marriott Hotel",
    RoadDevelopmentZone: "No.365, Huanghe Road, Development Zone",
    BeachResorthotel: "Sheraton Yantai Golden Beach Resort hotel",
    GardenHotelYantai: "Benaratine Garden Hotel Yantai",
    RoadDevelopmentZones: "No. 56 haibin Road, Development Zone",
    anInternationalHotel: "Yantai Hua 'an International Hotel",
    BinhaiRoad88: "88 Binhai Road, Development Zone",
    BinhaiRoad: "1 Binhai Road, Development Zone",
    YantaiKunlun: "Yantai Kunlun International Hotel",
    ChangjiangRoad: "218 Changjiang Road, Development Zone",
    TianmaYargeHotel: "Yantai Tianma Yarge Hotel Apartment",
    NoChangjiangRoad: "No. 161 Changjiang Road, Development Zone",
    HolidayInn: "Holiday Inn, Yantai",
    qinhuaiheRoad: "No. 10 qinhuaihe Road, Development Zone",
    YantaiGoldenBeachHotel: "Yantai Golden Beach Hotel",
    HuangheRoad88: "88 Huanghe Road, Development Zone",
    TimesBuilding: "Yantai New Times Building",
    HuangheRoad86: "86 Huanghe Road, Development Zone",
    YantaiFuteDaysHotel: "Yantai Fute Days Hotel",
    HebinRoad12: "12 Hebin Road, Development Zone",
    //交通指南
    oftransportation: "<span style='font-size: 17.5px;'>means of transportation</span>",
    oftransportations: "means of transportation:",
    expressway: "expressway",
    kilometersofexpressways: "By 2020, 670 kilometers of expressways will be open to traffic, forming a modern highway network with shenhai, Rongwu, Weiqing, Yanhai, Longqing, Pengqi, Brunei Expressway and national Highway 204 and 206 as the main frame and reaching all over the world, making external travel very convenient.  ",
    Railandhighspeedrail: "Rail and high speed rail",
    threelongitudinal: "As an important city in the 'three longitudinal and three horizontal' railway network of Shandong Province, Qingdao can be reached by qingrong intercity high-speed railway in 1 hour, and Beijing can be reached in 3 hours by bohai Rim intercity high-speed railway to be completed soon, and there is also a ferry railway from Yantai to Dalian.  ",
    port: "port",
    Yantaiport: "Opened in 1861, Yantai port is one of the 25 key coastal ports and 15 strategically important ports in the 'Belt and Road' initiative. Its cargo throughput ranks among the top 10 in China.  It has one 400,000-ton world class ore terminal and one 300,000-ton world class crude oil terminal, and 242 berths of various types, including 74 deep water berths of 10,000-ton class and above.  The Yantai - Dalian ferry will travel from Shandong Peninsula to Liaodong Peninsula in six hours, effectively deepening the links between North and East China and northeast China. By 2020, the passenger throughput will reach 5.8 million.  ",
    airport: "airport",
    YantaiPenglaiInternational: "By the end of 2020, Yantai Penglai International Airport has opened 169 routes to 94 domestic, international and regional cities, forming a route network pattern 'facing Japan and South Korea, connecting north and south, connecting northwest and radiation southwest'.  The penglai International Airport has an annual passenger capacity of 10 million. The second phase of the airport will be completed in 2022.  ",
    // 搭建服务
    providesyouwith: "The exhibition center provides you with standardized booth construction and exhibition equipment rental services",
    StandardBooth: "Standard Booth (Standard Booth)",
    Standardboothisintroduced: "Standard booth is introduced",
    specialbooth: "special booth",
    boothdetailedintroduction: "Standard booth detailed introduction",
    // 会议中心
    conferencecenter: "conference center",
    floorsandanarea: "The conference area has 5 floors and an area of 30,000 square meters. According to the needs, there are a total of 30 conference rooms of different sizes, which can accommodate more than 3,600 people in total, and can undertake various kinds of conferences, banquets, forums and lectures, corporate annual meetings and other large indoor activities.  ",
    PeopleLectureHall: "Thousand People Lecture Hall",
    framestage: "12m high, frame stage.",
    Onethousandpeople: "One thousand people banquet hall",
    separabledesign: "Nearly 2000 square meters, separable design, to meet the needs of different scales of banquets.",
    Othermeetingrooms: "Other meeting rooms",
    Centertechnicaldata: "Conference Center technical data",
    conferenceroom: "conference room",
    msquared: "The area is m squared.",
    Lengthwidthheight: "Length * Width * Height (m)",
    seatingcapacity: "seating capacity",
    ThechamberC301: "The chamber C301",
    VIPreceptionroomC301: "VIP reception room C301",
    // 展会排期
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    In2021: "In 2021",
    HotelSuppliesand: "The 7th Yantai International Hotel Supplies and Catering Expo yantai International Tea...  ",
    Internationa: "The 23rd China (Yantai) International Architectural Decoration Expo",
    InternationalHotel: "The 7th Yantai International Hotel Supplies and Catering Expo yantai International Tea...  ",
    InternationalArchitectural: "The 23rd China (Yantai) International Architectural Decoration Expo",
    AddressExhibitionhall: "Address: Exhibition hall, 1st floor, Zone D",
    ShandongYantaiGonghao: "Organizer: Shandong Yantai Gonghao Times Exhibition Co., LTD",
    ContactpersonHuangChaohai: "Contact person: Huang Chaohai 15278275562",
    nextpage: "next page",
    address: "Address: exhibition hall on the first floor of Zone D",
    Sponsor: "Organizer: Shandong Yantai gonghao times Exhibition Co., Ltd",
    contacts: "Contact: Huang Chaohai",
    // 餐饮服务
    foodandbeverage: "Supporting the food and beverage",
    metersandtheauxiliary: "The central kitchen of 3,000 square meters and the auxiliary kitchen of 2,500 square meters can provide various forms of food enjoyment for exhibitors, conference and other personnel",
    Exhibitioncatering: "Exhibition catering",
    forexhibitiongueststo: "Exhibition supporting catering, for exhibition guests to provide convenient, characteristic catering services  ",
    banquetcatering: "The banquet catering",
    cateringprovidyouwith: "Luxurious banquet catering, provide you with delicious food, enjoy the ultimate catering services  ",
    // 新闻动态
    InvestmentFranchiseand: "2021 China International Hotel Investment Franchise and Franchise Exhibition grand opening!",
    ConventionandExhibitionCo: "The 2021 China International Hotel Investment Franchise and Franchise Exhibition, hosted by Yantai Bajiaowan International Convention and Exhibition Co., LTD., was launched at 9:00 a.m. on 26th in Bajiaowan International Convention and Exhibition Center No.2 Hall.  The exhibition gathered many domestic and foreign head hotel management groups and hotel supplies, smart hotel, hotel design engineering quality enterprises......",
    bloomingpengchengTheGreatBay: "Break cocoon into butterfly, blooming pengcheng!  The Great Bay Area Doors, Windows and Building Fire Protection Expo 2021 will open on May 13!  ",
    Professionalandtechnical: "After 11 months of publicity and promotion, planning and preparation!  Bay Area for the first time to open the international window curtain wall and building fire expo era!  The first day of audience flow 6059 person-time!  Professional and technical communication atmosphere is rich!  Break cocoon into butterfly, blooming pengcheng!  Audience mainly from Shenzhen, Hong Kong, Macao, Guangzhou......  ",
    andExhibitionCentre: "ISLE 2021 at Bajiao Bay International Convention and Exhibition Centre",
    Continuouslyenrichtheadvantages: "As a leading international platform for business exchange and cooperation of the whole industry chain, such as large screen display, audio and video integration, advertising signs and commercial space, it was held in Bajiaowan International Convention and Exhibition Center (Baoan New Hall) from May 10-13.  Continuously enrich the advantages of the exhibition of 'full category + whole industry chain'......  ",
    modificationexhibitionis: "Asia's largest car modification exhibition is about to open in bajiao Bay International Convention and Exhibition Center",
    responsiblepersonlong: "All the dealers at all levels of the country's provinces, cities and counties, terminal shop owners, modification factories, modification shop owners, modification studios, global cross-border e-commerce platform responsible person, long live in the domestic overseas buyers, etc., more than 300 national authoritative media, industry media leaders and representatives gathered in the venue......  ",
    viewdetails: "view details",
    classstyle: "class style",
    theaterstyle: "theater style ",
    converseU: "form-U",
    feast: "feast",
    wonderful: "wonderful",
    NewsInformation: "NewsInformation",
    FoodandBeverages: "FoodandBeverages",
    NoticeNotice:"NoticeNotice",
    //旅游景点
    PenglaiPavilionScenicArea: "Penglai Pavilion Scenic Area",
    YantaiGoldenBeachSeasidePark: "<span style='font-size:14px'>Yantai Golden Beach Seaside Park<span/>",
    YantaiMountainPark: "Yantai Mountain Park",
    PenglaiOceanPolarWorld: "Penglai Ocean Polar World",
    YantaiyangmaIslandtouristresort: "<span style='font-size:14px'>Yantai yangma Island tourist resort<span/>",
    Yantaimagneticmountainhotspringtown: "<span style='font-size:13px'>Yantai magnetic mountain hot spring town<span/>",
    ChangyuWineCultureMuseum: "Changyu Wine Culture Museum",
    LongIslandScenictouristarea: "Long Island Scenic tourist area",
    // 资料下载
    Downloadthedata: "Download the data",
    // 移动端导航
    AboutUs: "About Us",
    Thepavilionguide: "The pavilion guide",
    Showthedynamic: "Show the dynamic",
    exhibitionservices: "exhibition services",
    datadownload: "data download",
    Peripheralservices: "Peripheral services",
    Freightentrance: "Freight entrance",
    Constructionheight: "Construction height",
    ExhibitionProcess:"Exhibition process",
}