export const language = {
    abloutwe: "关于我们",
    DeliciousYantai: "鲜美烟台",
    DynamicDevelopmentZone: "活力开发区",
    CompanyIntroduction: "公司介绍",
    vrView: "VR导览",
    ContactUs: "联系我们",
    PavilionGuide: "展馆指南",
    ExhibitionHallSurvey: "展馆概况",
    exhibitionGround: "展览场地",
    ConferenceCenter: "会议中心",
    ExquisiteGroupPictures: "精美组图",
    ExhibitionTrends: "展会动态",
    NewInformation: "新闻动态",
    WonderfulMoment: "精彩瞬间",
    ExhibitionScheduling: "展会排期",
    ExhibitionServices: "展会服务",
    DataDownload: "资料下载",
    HallPlan: "展馆平面图",
    SiteDynamicLineMap: "场地动线图",
    Brochure: "宣传手册",
    Cityshoreseaofcloud: "城岸云浪",
    ManagementRegulations: "管理规定",
    ApprovalDocument: "报批文件",
    PeripheralServices: "周边服务",
    TrafficGuide: "交通指南",
    ParkingInformation: "停车信息",
    SurroundingHotels: "周边酒店",
    ScenicSpot: "旅游景点",
    Chinese: "中",
    Assemblyarea: "会议区域",
    Theexhibitionarea: "展览区域",
    InternationalConventionand: "ISLE 2021于烟台国际会展中心盛大举办",
    En: "En",
    andExhibitionCenter: "年度重磅 · 烟台国际家具展即将盛大开幕！ISLE 2021于烟台国际会展中心盛大举办",
    YantaiInternationalFurniture: "年度重磅 · 烟台国际家具展即将盛大开幕！",
    giftExhibitionwill: "24万㎡的烟台礼品展即将于烟台国际会展中心...",
    inbookingthevenue: "作为主办方，我有意向预定场地",
    hostentry: "主办入口",
    ExhibitorEntrance: "展商入口",
    ExhibitorEntranceText: "作为展商,我有意向参与布展",
    AudienceEntrance: "观众入口",
    AudienceEntranceText: "作为观众,我有意向参观展览",
    PavilionNews: "展馆新闻",
    preview: "预览",
    ExhibitionHallScheduling: "展馆排期",
    ExhibitionEquipmentRental: "展具租赁",
    FoodAndBeverages: "餐饮服务",
    ExhibitionProcess: "办展流程",
    BuildService: "搭建服务",
    AdvertisingServices: "广告服务",
    EntranceMovingLine: "入场动线",
    VenueTechnicalParameters: "场馆技术参数",
    leftTitle: "2021年中国国际酒店投资加盟及特许经营...",
    Organizer: "主办单位:烟台八角湾国际会展中心",
    LeftNews: "2021年中国农业产品博览会",
    CenterTitle: "2021年中国国际酒店投资加盟及特许...",
    CenterText: "由山东烟台国际会展有限公司主办的2021中国国际酒店投资加盟与特许经营展览会于26日上午9:00",
    RightTitle: "2021年中国国际酒店投资加盟及特许...",
    RightText: "由山东烟台国际会展有限公司主办的2021中国国际酒店投资加盟与特许经营展览会于26日上午9:00",
    BrowseMore: "浏览更多",
    Link: "友情链接：中国会展经济研究会 中国展览馆协会 烟台业达成式发展集团有限公司",
    Linkx: "烟台业达国际会展有限公司 烟台业达文旅有限公司",
    ExhibitionCore: "烟台八角国际会展中心",
    Telephone: "联系电话",
    Address: "地址: 烟台经济技术开发区八角湾",
    WeChatOfficialAccount: "微信公众号",
    Record: "COPYRIGHT © 2021 烟台八角湾国际会展中心 ALLRIGHTS RESEVRED  | 鲁ICP备2021032259号",
    Follow: "关注",
    // 鲜美烟台
    current: "当前位置",
    HOMEpage: "首页",
    introduced: "烟台名称源于烟台山，是国务院批复确定的中国山东半岛的中心城市，环渤海地区重要的港口城市，是国家历史文化名城。2020年，烟台市继续保留全国文明城市荣誉称号，成为全国唯一的“六连冠”地级城市。 烟台地处山东半岛中部，东连威海，西接潍坊，西南与青岛毗邻，北濒渤海黄海，与辽宁半岛对峙，并与大连隔海相望，共同形成拱卫首都北京的海上门户。 烟台是环渤海经济圈、胶东经济圈内重要节点城市、中国首批14个沿海开放城市之一，中国海滨城市，“一带一路”国家战略重点建设港口城市。 烟台山青水秀，气候宜人，有丰富的自然景观和人文景观。尤其是夏季，海风抚面，清爽可人；山花烂漫，沁人心脾；海光山色，交相辉映。既是避暑纳凉之胜地，也是各地游客旅游观光的理想境地。市内旅游景区众多，有国家5A级旅游景区3处、4A级19处、3A级50处，获“中国优秀旅游城市”称号。",
    honor: "城市荣誉",
    InternationalWineCity: "VIO国际葡萄酒城",
    HabitatAward: "联合国人居奖",
    investmentenvironment: "中国投资环境金牌城市",
    citiesinChina: "中国十大文明城市",
    CityinChina: "最佳中国魅力城市",
    beautifulCITY: "中国最美丽城市",
    HealthCity: "国家卫生城市",
    GardenCity: "国家园林城市",
    ForestCity: "国家森林城市",
    resortcity: "避暑旅游城市",
    competitivecity: "中国最具生态竞争力城市",
    shandongyantai: "地址<span style='margin:0 5px 0 3px;'>   :   </span>山东省烟台经济技术开发区北京中路31号",
    // bottom公共组件
    bq: "版权所有 烟台八角湾国际会展中心",
    bqcontent: "COPYRIGHT © 2021 烟台八角湾国际会展中心. ALLRIGHTS RESEVRED | 鲁ICP备15003446号-1",
    friendship: "友情链接: 中国会展经济研究会 中国展览馆协会 烟台业达城市发展集团有限公司",
    Developmentofthecompany: "烟台业达国际会展有限公司 烟台业达文旅有限公司",
    Thepublic: "微信公众号",
    // 展馆概况
    ExhibitionCenter: "烟台八角湾国际会展中心",
    expl: "烟台八角湾国际会展中心位于烟台开发区八角街道,占地13.9万平方米,建筑面积24.6万平方米, 总投资约33亿元,由美国著名设计公司 AECOM根据第五代会展综合体定位设计,以“城岸云浪·海上云贝”为设计理念,将烟台的碧海与蓝天相接,海浪与白云相交融,再融入轻盈的银贝最终演化出建筑结构与造型相互统一、结构清晰的,集展览区、会议区和配套商业区为一 体的会展商业体",
    YantaiBajiao: "占地13.9万平方米,建筑面积24.6万平方米, 总投资约33亿元",
    Theexhibition: "会展中心展览区分为室内展区和室外展区,室内共设有8个展厅,室内展览面积5.6 万平方米,总展位2800个;室外展览面积约3.3万平方米,可为各类会展活动提供展览空间。 ",
    Theconference: "会展中心会议区共5层、面积3万平方米,根据需求设有不同规模的会议室共计30 个,总计可容纳3600余人,可承接各类会议、宴会、论坛讲座、企业年会等大型室内活动 ",
    Inaddition: "此外,会展中心整体设计采用环保先进的建筑技术及智能系统,配备有智能停车技术的室内外停车位1800余个;2500平方米的配套厨房可为参展商、会议等人员提供多种形式的美食享受。",
    // 活力开发区
    Approvedbythe: "1984年10月经国务院批准设立，1985年3月开工建设，是全国首批14个国家级开发区之一、中国（山东）自由贸易试验区烟台片区承载地，是烟台国际招商产业园、中韩产业园、中日产业园和综保区主阵地，山东新旧动能转换核心区。 先后荣获ISO14000国家示范区、中国工业园区环境管理示范区、全国循环经济试点园区、国家新型工业化示范基地、国家知识产权试点  园区、全国模范劳动关系和谐工业园区、联合国绿色工业园区等称号。2020年地区生产总值增长5.5%，固定  资产投资增长7%，进出口总额增长14.9%，实际使用外资增长16.6%，一般公共预算收入  增长5.3%，主要经济指标均高于省市平均水平。",
    WonderfuloctagonBay: "精彩八角湾",
    BajiaoBayis: "八角湾，一片孕育着希望的热土，“创新”是它的一个“标签”，“文旅会展”也是这片区域的鲜明特色。",
    BajiaowanCentral: "位于烟台开发区中心位置的八角湾中央创新区，主要打造的是中央创新区。重点布局中央商务区、科技创新区、会展文旅区“三大板块”。八角湾部分区域亦被划入山东自贸试验区烟台片区和中韩(烟台)产业园范围内。  ",
    Asoneofthenine: "作为烟台市重点规划建设的九大片区之一，八角湾位于烟台开发区西侧，已成为最具有发展潜力的黄金湾区之一，这一点，从其周边产业布局即可见一斑。八角湾周边聚集了万华、富士康、通用汽车等一批优质产业龙头项目。紧临“三港一站”，距蓬莱国际机场10公里、环渤海高铁烟台西站5.8公里，荣乌高速、206国道穿境而过，北邻烟台港西港区，集区位、自然资源、经济腹地万千优势于一身。 ",
    // 公司介绍
    YantaiYedaUrban: "烟台业达城市发展集团有限公司是烟台经济技术开发区区属重点国有企业，组建于2019年9月，注册资本30亿元。截至2020年末，资产总额约265亿元，净资产159亿元，现有员工400余人。是一家集城市建设、城市更新及综合运营服务、会展文旅和海洋经济、产业培育和金融服务“3+1”国有资本投资运营平台。",
    YantaiYeDaWen: "烟台业达文旅有限公司",
    Foundedin2019with: "烟台业达文旅有限公司成立于2019年，注册资本5亿元，是区属国有企业烟台业达城市发展集团有限公司旗下全资子公司。业达文旅集团秉承“让生活更美好”的品牌理念，以提升现代化滨海新城品质为宗旨，繁荣服务业态、拓展海洋经济，深挖文化旅游、留住城市底蕴，致力打造烟台最具吸引力的城市板块，开拓会展文旅新篇章，为建设现代化、靓丽文明的滨海新城助力！ ",
    YantaiYedaInternational: "烟台业达国际会展有限公司",
    YantaiYedaInternationalExhibitionCo: "烟台业达国际会展有限公司成立于2020年8月，注册资本500万元，是区属国有企业烟台业达城市发展集团有限公司旗下三级全资子公司。业达国际会展有限公司是业达城发集团履行城市运营服务功能的核心企业之一，是烟台八角湾国际会展中心场馆运营商。公司下设营销部、营运部、会议餐饮部、工程物业部、人事行政部及财务部，致力于用国际化的经营理念、先进的管理体系、至臻的服务品质，为参会人员提供集展览、会议、餐饮、休闲、观光购物于一体的极致服务体验。 ",
    // 联系我们
    Site: "地址",
    YantaiEconomic: "山东省烟台经济技术开发区",
    MiddleRoad: "北京中路31号",
    Phone: "联系电话",
    Mailbox: "邮箱",
    WechatQRcode: "微信二维码",
    // 展馆场地
    Venueintroduction: "场地简介",
    Logontothehall: "登录大厅",
    Themulti: "多功能厅",
    Exhibitiongalleriesforindoor: "展览区分为室内展区和室外展区，室内共设有8个展厅，室内展览面积5.6万平方米，总展位2800个，由双首层五加三展厅的标准单元构成，利用场地自然高差巧妙地使8个展厅都能实现地面平进，每个展厅均拥有独立的物流及人流通道；室外展厅面积约3.3万平方米，可为各类会展活动提供展览空间。 ",
    Thedesignstyleoftheentry: "登录厅设计风格与外观建筑和谐统一，高挑的室内空间和简约的线条勾勒，让人仿佛置身云海。“繁华虽少减，高雅亦足奇”，大面积超白玻璃幕墙充分的将自然光线引入进来，营造出素雅大气之感。 ",
    Thelargepillarfunctional: "4000平米超大无柱多功能厅，简约不失奢华，大气又显精致。适合举办大型会议和宴会、企业年会、各种定制活动。 ",
    ofexhibitioncenter: "展览中心技术数据",
    facility: "设施",
    A1A2pavilion: "A1、A2馆",
    A3A4: "A3、A4馆",
    B1B2B3: "B1、B2、B3馆 ",
    functionhall: "多功能厅",
    loginhall: "主、次登录大厅 ",
    supply: "供电量",
    kvapavilion: "3200kVA/馆",
    supplymode: "供电方式",
    lighting: "三相五线制 照明电源22伏 50赫兹",
    wiresystem: "三相五线制",
    compressedair: "压缩空气",
    Thereisno: "无",
    twoposition: "Ø12，双位",
    Whoseheight: "展厅高度",
    meters129: "12.9米",
    lowestpoint: "26.1米（最低点）",
    lowespoint: " 33.1米、26.5米、19.4米 （最低点）",
    lowepoint: "32.4米、22.6米 （最低点）",
    Buildallowedheight: "搭建允许高度",
    meters6: "6米",
    structuralliftingpoints: "结构吊点数量",
    liftingpointbearing: "结构吊点承重",
    ofexhibitionhall: "展厅地面承重",
    tonspersquaremeter2: "2吨/平方米 ",
    tonspersquaremeter5: "5吨/平方米 ",
    tonspersquaremeter35: "0.35吨/平方米",
    Tothegate: "给水口",
    DN15DN25: "有DN15，DN25 ",
    DN50DN100: "有DN50，DN100",
    airconditioner: "空调",
    Thereare: "有",
    Freshair: "新风",
    Telephonecontact: "电话接点",
    TheInternetpoint: "internet点",
    ExhibitionHall: "展厅",
    Area: "面积",
    StoryHeight: "层高",
    LoadBearing: "承重",
    LiftingPointNumber: "吊点数量/承重",
    //周边酒店
    WestinHotels: "威斯汀酒店群",
    FiveStar: "五星级",
    Locatednextto: "位于开发区八角湾国际会展中心旁，占地面积5.4万平方米，由三座国际知名星级酒店和一座服务型公寓组成，客房总数约1200间。",
    WestinHotelconstruction: "威斯汀酒店，建筑面积4.5万平方米，配置面积55平米、层高4.2米的客房269间。 雅乐轩酒店，建筑面积3万平方米，配置面积36平方米、层高3.6米的客房361间。",
    TheHyattPlacehas336: "凯悦嘉轩酒店，设有面积36平方米、层高3.6米的客房336间。",
    Thefirstfloorof: "服务型公寓首层与凯悦嘉轩酒店相连接，设有面积36平方米的客房201间。",
    Morehotels: "更多酒店",
    hotelname: "酒店名称",
    starlevel: "星级",
    hoteladdress: "酒店地址",
    Mileage: "里程（km）",
    Drive: "车程（min)",
    numberofrooms: "房间总量（间）",
    CoastHotel: "烟台金海岸希尔顿酒店",
    DevelopmentZone: "开发区宁波路1号",
    MarriottHotel: "烟台鑫广万豪酒店",
    RoadDevelopmentZone: "开发区黄河路365号",
    BeachResorthotel: "烟台金沙滩喜来登度假酒店",
    GardenHotelYantai: "烟台百纳瑞汀花园酒店",
    RoadDevelopmentZones: "开发区海滨路56号",
    anInternationalHotel: "烟台华安国际大酒店",
    BinhaiRoad88: "开发区滨海路88号",
    BinhaiRoad: "开发区滨海路1号",
    YantaiKunlun: "烟台昆仑国际酒店",
    ChangjiangRoad: "开发区长江路218号",
    TianmaYargeHotel: "烟台天马雅阁酒店公寓",
    NoChangjiangRoad: "开发区长江路161号",
    HolidayInn: "烟台福莱智选假日酒店",
    qinhuaiheRoad: "开发区秦淮河路10号",
    YantaiGoldenBeachHotel: "烟台金沙滩大酒店",
    HuangheRoad88: "开发区黄河路88号",
    TimesBuilding: "烟台新时代大厦",
    HuangheRoad86: "开发区黄河路86号",
    YantaiFuteDaysHotel: "烟台辅特戴斯酒店",
    HebinRoad12: "开发区河滨路12号",
    //交通指南
    oftransportation: "<span style='font-size: 30px;margin-right:95px;'>交通方式</span>",
    oftransportations: "交通方式:",
    expressway: "高速公路",
    kilometersofexpressways: "截止2020年，通车高速公路670公里，形成以沈海、荣乌、威青、烟海、龙青、蓬栖、文莱高速和国道204、206等高等级公路为主框架、四通八达的现代化公路网，对外出行十分便捷。",
    Railandhighspeedrail: "铁路与高铁",
    threelongitudinal: "作为山东省“三纵三横”铁路网重要城市，青荣城际高速铁路1小时可达青岛，即将建成的环渤海城际高速铁路3小时可达北京，另通有烟台至大连轮渡铁路。",
    port: "公交车路线",
    Yantaiport: "途经八角湾国际会展中心的公交线路有：201、206、208、215、216、526，（北京中路）停靠站点名称：烟台市光电产业园。",
    airport: "机场",
    YantaiPenglaiInternational: "截止2020年底，烟台蓬莱国际机场与94个国内、国际及地区城市通航,开通航线169条, 形成“面向日韩、贯通南北、连接西北、辐射西南”的航线网络格局。蓬莱国际机场年旅客吞吐量达1000万人次，机场二期工程将于2022年竣工。",
    // 搭建服务
    providesyouwith: "会展中心为您提供标准化展位搭建和展具租赁服务",
    StandardBooth: "标准展位（标摊）",
    Standardboothisintroduced: "标摊介绍",
    specialbooth: "特装展位",
    boothdetailedintroduction: "标摊详细介绍",
    // 会议中心
    conferencecenter: "会议中心",
    floorsandanarea: "会议区共5层、面积3万平方米，根据需求设有不同规模的会议室共计30个，总计可容纳3600余人，可承接各类会议、宴会、论坛讲座、企业年会等大型室内活动。",
    PeopleLectureHall: "千人报告厅",
    framestage: "12m挑高，镜框式舞台。",
    Onethousandpeople: "千人宴会厅",
    separabledesign: "近2000平方米，可拆分设计，满足不同规模宴会需求。",
    Othermeetingrooms: "其他会议室",
    Centertechnicaldata: "会议中心技术数据",
    conferenceroom: "会议室",
    msquared: "面积（m²）",
    Lengthwidthheight: "长*宽*高（m）",
    seatingcapacity: "可容纳人数",
    ThechamberC301: "会议厅C301",
    VIPreceptionroomC301: "VIP接待室C301",
    // 展会排期
    January: "1月",
    February: "2月",
    March: "3月",
    April: "4月",
    May: "5月",
    June: "6月",
    July: "7月",
    August: "8月",
    September: "9月",
    October: "10月",
    November: "11月",
    December: "12月",
    In2021: "2021年",
    HotelSuppliesand: "第七届烟台国际酒店用品及餐饮博览会 烟台国际茶饮...",
    Internationa: "第二十三届中国（烟台）国际建筑装饰博览会",
    InternationalHotel: "第七届烟台国际酒店用品及餐饮博览会 烟台国际茶饮...",
    InternationalArchitectural: "第二十三届中国（烟台）国际建筑装饰博览会",
    AddressExhibitionhall: "地址：D区一层展厅",
    ShandongYantaiGonghao: "主办方：山东烟台共好时代会展有限公司",
    ContactpersonHuangChaohai: "联系人：黄超海 15278275562",
    nextpage: "下一页",
    address: "地址：D区一层展厅",
    Sponsor: "主办方：山东烟台共好时代会展有限公司",
    contacts: "联系人：黄超海 ",
    // 餐饮服务
    foodandbeverage: "配套餐饮",
    metersandtheauxiliary: "3000平方米中央厨房、2500平方米的配套厨房可为参展商、会议等人员提供多种形式的美食享受",
    Exhibitioncatering: "会展餐饮",
    forexhibitiongueststo: "会展配套餐饮，为展会客人提供便捷、特色餐饮服务",
    banquetcatering: "宴会餐饮",
    cateringprovidyouwith: "豪华宴会餐饮，为您提供鲜美饕餮美食，尊享极致餐饮服务",
    // 新闻动态
    InvestmentFranchiseand: "2021年中国国际酒店投资加盟与特许经营展览会盛大开幕！",
    ConventionandExhibitionCo: "由烟台八角湾国际会展有限公司主办的2021中国国际酒店投资加盟与特许经营展览会于26日上午9:00在八角湾国际会展中心2号馆拉开帷幕。本次展览会集结了众多国内外头部酒店管理集团以及酒店用品、智慧酒店、酒店设计工程优质企业......",
    bloomingpengchengTheGreatBay: "破茧成蝶、绽放鹏城！2021大湾区门窗幕墙与建筑防火博览会5月13日盛大开幕！",
    Professionalandtechnical: "历经11个月的宣传推广、策划筹备！湾区首次开启国际门窗幕墙与建筑防火博览会时代！首日观众人流量6059人次！专业技术交流气氛浓郁！破茧成蝶、绽放鹏城！观众主要来自深圳、香港、澳门、广州......",
    andExhibitionCentre: "ISLE 2021于八角湾国际会展中心盛大举办！",
    Continuouslyenrichtheadvantages: "作为领先的大屏幕显示与音视频集成、广告标识与商业空间等的全产业链商贸交流合作国际平台，于5月10-13日在八角湾国际会展中心(宝安新馆)盛大举办。不断充实展会“全品类+全产业链”的优势特色......",
    modificationexhibitionis: "亚洲最大的汽车改装展即将在八角湾国际会展中心惊艳开启",
    responsiblepersonlong: "全国省市县各级经销商、终端店主、改装厂、改装店主、改装工作室、全球跨境电商平台负责人、长居国内的海外买家等悉数到场，全国300多家权威媒体、行业媒体的领导及代表集聚场馆...... ",
    viewdetails: "查看详情 >>>",
    classstyle: "课桌式",
    theaterstyle: "剧院式",
    converseU: "U字型",
    feast: "宴会",
    wonderful: "精彩瞬间",
    NewsInformation: "新闻动态",
    FoodandBeverages: "餐饮服务",
    NoticeNotice:"通知公告",
    //旅游景点
    PenglaiPavilionScenicArea: "蓬莱阁风景区",
    YantaiGoldenBeachSeasidePark: "烟台金沙滩海滨公园",
    YantaiMountainPark: "烟台山公园",
    PenglaiOceanPolarWorld: "蓬莱海洋极地世界",
    YantaiyangmaIslandtouristresort: "烟台养马岛旅游度假区",
    Yantaimagneticmountainhotspringtown: "烟台磁山温泉小镇",
    ChangyuWineCultureMuseum: "张裕酒文化博物馆",
    LongIslandScenictouristarea: "长岛风景旅游区",
    // 资料下载
    Downloadthedata: "资料下载",
    // 移动端导航
    AboutUs: "关于我们",
    Thepavilionguide: "展馆指南",
    Showthedynamic: "展会动态",
    exhibitionservices: "展会服务",
    datadownload: "资料下载",
    Peripheralservices: "周边服务",
    Freightentrance: "货运入口",
    Constructionheight: "允许搭建高度",
}