import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
  // h5
  const h5router = [
    // 首页
    {
      path: '/index',
      name: 'index',
      component: () => import('../views/h5/indexs.vue'),
    },
    // 鲜美烟台
    {
      path: '/deliciousyt',
      name: 'deliciousyt',
      component: () => import('../views/h5/deliciousyt.vue')
    },
      // 展馆概况
    {
      path: '/exhibition',
      name: 'exhibition',
      component: () => import('../views/h5/exhibition.vue')
    },
    // 周边服务
    {
      path: '/traffic',
      name: 'traffic',
      component: () => import('../views/h5/traffic.vue')
    },
    //展会服务
    {
      path: '/ExhibitionServices',
      name: 'ExhibitionServices',
      component: () => import('../views/h5/ExhibitionServices.vue')
    },
    // 展会动态
    {
      path: '/TradeShowTrends',
      name: 'TradeShowTrends',
      component: () => import('../views/h5/TradeShowTrends.vue')
    },
    // 资料下载
    {
      path: '/datadownload',
      name: 'datadownload',
      component: () => import('../views/h5/datadownload.vue')
    },
      // 查看详情
  {
    path: '/lookparticulars',
    name: 'lookparticulars',
    component: () => import('../views/h5/lookparticulars.vue')
  },
  {
    path: '/lookparticularsNav',
    name: 'lookparticularsNav',
    component: () => import('../views/h5/lookparticularsNav.vue')
  },
  // 路由重定向
    {
      path:"/",
      redirect:"/index"
    }
  ]
  // h5
  // pc
const pcroutes = [
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/pc/index.vue')
  },
  // 鲜美烟台
  {
    path: '/deliciousyt',
    name: 'deliciousyt',
    component: () => import('../views/pc/deliciousyt.vue')
  },
// 展馆概况
  {
    path: '/exhibition',
    name: 'exhibition',
    component: () => import('../views/pc/exhibition.vue')
  },
  //展会动态
  {
    path: '/TradeShowTrends',
    name: 'TradeShowTrends',
    component: () => import('../views/pc/TradeShowTrends.vue')
  },
  //展会服务
  {
    path: '/ExhibitionServices',
    name: 'ExhibitionServices',
    component: () => import('../views/pc/ExhibitionServices.vue')
  },
  //周边服务
  {
    path: '/traffic',
    name: 'traffic',
    component: () => import('../views/pc/traffic.vue')
  },
  // 查看详情
  {
    path: '/lookparticulars',
    name: 'lookparticulars',
    component: () => import('../views/pc/lookparticulars.vue')
  },
  {
    path: '/lookparticularsNav',
    name: 'lookparticularsNav',
    component: () => import('../views/pc/lookparticularsNav.vue')
  },
  //资料下载
  {
    path: '/datadownload',
    name: 'datadownload',
    component: () => import('../views/pc/datadownload.vue')
  },
  //办展流程
  {
    path: '/Exhibitionprocess',
    name: 'Exhibitionprocess',
    component: () => import('../views/pc/Exhibitionprocess.vue')
  },
  // 路由重定向
  {
    path:"/",
    redirect:"/index"
  },
]
  // pc
  

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: document.body.clientWidth <= 1030 ? h5router : pcroutes,
})
export default router