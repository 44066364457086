import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import * as Api from './api/api'
import * as util from './util/util'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import VueAwesomeSwiper from 'vue-awesome-swiper';

import "swiper/dist/css/swiper.css";

Vue.use(VueAwesomeSwiper)

Vue.use(Viewer, {
    defaultOptions: {
        zIndex: 9999
    }
})
Viewer.setDefaults({
    Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})

Vue.use(ElementUI);
Vue.use(VueI18n);
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$api = Api;
Vue.prototype.$util = util;

const i18n = new VueI18n({
    locale: localStorage.getItem('locale') || 'zh-CN', // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'zh-CN': require('./common/lang/zh'), // 中文语言包
        'en-US': require('./common/lang/en') // 英文语言包
    }
})

new Vue({
    el: '#app',
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
