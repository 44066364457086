import service from "../util/service"
const apiBaseUrl = "/prod-api/"
const XQ = (url, params, callback) => {
    service.request({
        url: apiBaseUrl + url + params,
        method: "GET"
    }).then((ok) => {
        callback(ok)
    }).catch((err) => {
        callback(err)
    })
}
const GET = (url, params, callback) => {
        service.request({
            url: apiBaseUrl + url,
            method: "GET",
            params
        }).then((ok) => {
            callback(ok)
        }).catch((err) => {
            callback(err)
        })
    }
    // 资料下载
const GETDown = (url, params, callback) => {
        service.request({
            url: apiBaseUrl + url,
            method: "GET",
            responseType: 'blob',
            params
        }).then((ok) => {
            callback(ok)
        }).catch((err) => {
            callback(err)
        })
    }

// 展会排期列表
export const exhibition = (data, callback) => GET('/system/protal/zhList', data, callback);
// 新闻列表
export const newsandtrends = (data, callback) => GET('/system/protal/newList', data, callback);
// 新闻详情
export const lookparticulars = (data, callback) => XQ('/system/protal/', data, callback);
// 酒店信息
export const hotelinformation = (data, callback) => GET('/system/protal/getHotelList', data, callback);
// 会议中心
export const conferencecenter = (data, callback) => GET('/system/protal/getMeetList', data, callback);
// 展览中心技术数据
export const exhibitioncenter = (data, callback) => GET('/system/protal/getExhibitionList', data, callback);
// 资料下载列表
export const datadownload = (data, callback) => GET('/system/protal/fileList', data, callback);
// 资料下载
export const uploaddata = (data, callback) => GETDown('/system/protal/downloadHttp', data, callback);
// 精美组图
export const imgs = (data, callback) => GET('/system/protal/picList', data, callback);
// 最新通知公告
export const newTzggFirst = (data, callback) => GET('/system/protal/newTzggFirst', data, callback);
//展馆
export const exhibitionHallcenter = (data, callback) => GET('/system/protal/getExhibitionHallList', data, callback);