<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "dormInfo",
  props: {},
  components: {},
  computed: {},
  data() {
    return {
      screenWidth: document.body.clientWidth,
    };
  },
  methods: {
    
  },
  mounted() {
    console.log(document.body.clientHeight);
    const that = this;
    window.onresize = () => {
      return (() => {
        console.log(document.body.clientHeight);
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  created() {
    console.log(document.body.clientHeight);
  },
  watch: {
    /* 监听*/
    screenWidth(val) {
      this.screenWidth = val;
      if (this.screenWidth <= 414) {
        history.go(0);
      } else {
        history.go(0);
      }
    },
  },
};
</script>
<style lang="scss">
</style>
